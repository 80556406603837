<template>
  <div class="radio_container">
    <input
      :id="`${name}_radio_${String(value)}`"
      :name="name"
      class="radio"
      type="radio"
      :checked="state"
      :value="value"
      :disabled="disabled"
      @change="v => $emit('input', value)"
      @click="v => $emit('click')"
    >
    <label :for="`${name}_radio_${String(value)}`" class="circle">
      <div />
    </label>
    <label
      class="label"
      :id="`${name}_label`"
      :for="`${name}_radio_${String(value)}`"
      :disabled="disabled"
    >
      <slot class="opacity-75">
        {{label}}
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    name: {
      required: true,
      type: String
    },
    label: {
      type: String,
      required: false
    },
    value: {
      required: true
    },
    modelValue: {
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    state () {
      if (this.modelValue === null || this.modelValue === undefined) {
        return this.checked
      }
      return this.modelValue === this.value
    }
  }
}
</script>

<style lang="sass" scoped>

.radio_container
  @apply relative pl-6

.radio
  @apply opacity-0 appearance-none
  &:checked + label
    &> div
      @apply bg-primary w-2 h-2 bg-center bg-no-repeat
      mask-image: url('../../assets/images/inputs/circle-solid.svg')
  &:disabled + label,
  &:disabled + label
    @apply cursor-not-allowed opacity-50
  &:not(:disabled):hover + label,
  &:not(:disabled):hover + label
    @apply cursor-pointer opacity-75

.label
  @apply inline-block cursor-pointer select-none text-sm leading-normal mr-3
  &:not([disabled]):hover
    @apply opacity-75
  &[disabled]
    @apply cursor-not-allowed opacity-50

.circle
  @apply absolute rounded-full border border border-secondary left-0 w-4 h-4 flex items-center justify-center
  top: 3px

</style>
