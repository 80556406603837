<template>
  <div class="form_content">
    <p class="title">Dados da empresa</p>
    <p class="warning text-center" v-show="!$store.state.business.preencheuDadosEmpresa">Revise e complete esse
      formulário com os
      dados da
      sua empresa
      para prosseguir com a próxima etapa de preenchimento dos dados do(s) sócio(s).</p>
    <form class="relative" @submit.prevent="submit">
      <div class="backdrop" v-show="loadingPage">
        <div class="fas fa-dollar-sign spin"></div>
      </div>
      <p class="form_title">DADOS DA EMPRESA</p>
      <div class="row">
        <v-input class="w-full lg:w-3/12 lg:pr-6" v-uppercase name="razaoSocial" v-model="dadosEmpresa.razaoSocial"
          :error="errors.first('razaoSocial')" label="Razão Social*" v-validate="'required|min:3'"></v-input>
        <v-input class="w-full lg:w-4/12 lg:pr-6" v-uppercase name="nomeComercialFantasia"
          v-model="dadosEmpresa.nomeComercialFantasia" :error="errors.first('nomeComercialFantasia')"
          label="Nome Comercial Fantasia" v-validate="'min:3'"></v-input>
        <v-input class="w-full lg:w-3/12 lg:pr-6" name="ramoAtividade" v-model="dadosEmpresa.ramoAtividade"
          :error="errors.first('ramoAtividade')" label="Ramo de Atividade*" v-validate="'required|min:2'"></v-input>
        <v-input class="w-full lg:w-2/12" name="inscricaoEstadual" v-model="dadosEmpresa.inscricaoEstadual"
          :error="errors.first('inscricaoEstadual')" label="Inscrição Estadual" v-validate="'min:3'"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-1/4 lg:pr-6" type="money" name="faturamentoUltimos90Dias"
          v-model="dadosEmpresa.faturamentoUltimos90Dias" :error="errors.first('faturamentoUltimos90Dias')"
          label="Faturamento Ultimos 90 dias*" v-validate="'decimal:2|min_value:1'"></v-input>
        <v-input class="w-full lg:w-1/4 lg:pr-6" type="money" name="capitalSocial" v-model="dadosEmpresa.capitalSocial"
          :error="errors.first('capitalSocial')" label="Capital Social*" v-validate="'decimal:2|min_value:1'"></v-input>
        <!-- <v-input class="w-full lg:w-1/4 lg:pr-6" type="money" name="vendaDoUltimoExercicio"
                                  v-model="dadosEmpresa.vendaDoUltimoExercicio" :error="errors.first('vendaDoUltimoExercicio')"
                                  label="Vendas no último exercício*" v-validate="'decimal:2|min_value:1'"></v-input>-->
        <v-input class="w-full lg:w-1/4" type="money" name="patrimonioAproximado"
          v-model="dadosEmpresa.patrimonioAproximado" :error="errors.first('patrimonioAproximado')"
          label="Valor aproximado patrimônio*" v-validate="'decimal:2|min_value:1'"></v-input>
      </div>
      <div class="row">
        <!-- <v-input class="w-full lg:w-3/12" type="money" name="comprasUltimoExercicio"
                                  v-model="dadosEmpresa.comprasUltimoExercicio" :error="errors.first('comprasUltimoExercicio')"
                                  label="Compras no último exercício*" v-validate="'decimal:2|min_value:1'"></v-input> -->
        <v-input class="w-full lg:w-2/12 lg:pr-6" :mask="['(##) ####-####', '(##) #####-####']" :masked="true"
          name="telefone" v-model="dadosEmpresa.telefone" :error="errors.first('telefone')" label="Telefone*"
          v-validate="'required|min:14|max:15'"></v-input>
        <v-input class="w-full lg:w-4/12 lg:pr-6" v-lowercase name="email" v-model="dadosEmpresa.email"
          :error="errors.first('email')" label="Email da Empresa*" v-validate="'required|email'"></v-input>
        <v-input class="w-full lg:w-3/12 lg:pr-6" mask="##/##/####" :masked="true" name="dataConstituicao"
          v-model="dadosEmpresa.dataConstituicao" :error="errors.first('dataConstituicao')" label="Data de constituição*"
          v-validate="'required'"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-3/12 lg:pr-6" name="principaisProdutosVendidos"
          v-model="dadosEmpresa.principaisProdutosVendidos" :error="errors.first('principaisProdutosVendidos')"
          label="Principais produtos vendidos"></v-input>
        <v-input class="w-full lg:w-3/12 lg:pr-6" name="quantidadeSocios" v-model="dadosEmpresa.quantidadeSocios"
          :error="errors.first('quantidadeSocios')" label="Quantidade de sócios*"
          v-show="dadosEmpresa.quantidadeSocios == 0">
        </v-input>
        <v-input class="w-full lg:w-3/12 lg:pr-6" name="quantidadeEmpregados" v-model="dadosEmpresa.quantidadeEmpregados"
          :error="errors.first('quantidadeEmpregados')" label="Quantidade de empregados*"
          v-validate="'decimal:1|min_value:0'"></v-input>
        <v-input class="w-full lg:w-3/12" name="website" v-model="dadosEmpresa.website" :error="errors.first('website')"
          label="Website"></v-input>
        <v-input class="w-full lg:w-3/12" name="website" v-model="dadosEmpresa.instagram"
          :error="errors.first('instagram')" label="Instagram"></v-input>

      </div>
      <p class="form_title">ENDEREÇO DA SEDE SOCIAL</p>
      <div class="row">
        <v-input @blur="getCep" class="w-full lg:w-2/12 lg:pr-6" mask="#####-###" :masked="true"
          name="enderecoSedeSocial_CEP" v-model="dadosEmpresa.enderecoSedeSocial_CEP"
          :error="errors.first('enderecoSedeSocial_CEP')" label="CEP*" v-validate="'required|length:9'"></v-input>
        <v-input class="w-full lg:w-6/12 lg:pr-6" name="enderecoSedeSocial_Logradouro"
          v-model="dadosEmpresa.enderecoSedeSocial_Logradouro" :error="errors.first('enderecoSedeSocial_Logradouro')"
          label="Logradouro*" v-validate="'required|min:5'"></v-input>
        <v-input class="w-full lg:w-2/12 lg:pr-6" name="enderecoSedeSocial_Numero"
          v-model="dadosEmpresa.enderecoSedeSocial_Numero" :error="errors.first('enderecoSedeSocial_Numero')"
          label="Numero*" v-validate="'required:min:1'"></v-input>
        <v-input class="w-full lg:w-2/12" name="enderecoSedeSocial_Bairro"
          v-model="dadosEmpresa.enderecoSedeSocial_Bairro" :error="errors.first('enderecoSedeSocial_Bairro')"
          label="Bairro*" v-validate="'required|min:2'"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-6/12 lg:pr-6" name="enderecoSedeSocial_Complemento"
          v-model="dadosEmpresa.enderecoSedeSocial_Complemento" :error="errors.first('enderecoSedeSocial_Complemento')"
          label="Complemento"></v-input>
        <v-input class="w-full lg:w-2/12 lg:pr-6" @input="getCities" :options="ufs" itemText="name" itemValue="name"
          name="enderecoSedeSocial_UF" v-model="dadosEmpresa.enderecoSedeSocial_UF"
          :error="errors.first('enderecoSedeSocial_UF')" label="UF*" v-validate="{ required: true, is_not: -1 }">
        </v-input>
        <v-input class="w-full lg:w-4/12" :options="cities" itemText="name" itemValue="name"
          name="enderecoSedeSocial_Cidade" v-model="dadosEmpresa.enderecoSedeSocial_Cidade"
          :error="errors.first('enderecoSedeSocial_Cidade')" label="Cidade*" v-validate="{ required: true, is_not: -1 }"
          :disabled="!cities.length"></v-input>
      </div>
      <p class="form_title">RESPONSÁVEL FINANCEIRO</p>
      <div class="row">
        <v-input class="w-full lg:w-4/12 lg:pr-6" name="nomeFinanceiro" v-model="dadosEmpresa.nomeFinanceiro"
          :error="errors.first('nomeFinanceiro')" label="Nome Responsável Financeiro*" v-validate="'required'">
        </v-input>
        <v-input class="w-full lg:w-4/12 lg:pr-6" name="emailFinanceiro" v-model="dadosEmpresa.emailFinanceiro"
          :error="errors.first('emailFinanceiro')" label="Email Responsável Financeiro*" v-validate="'required|email'">
        </v-input>
        <v-input class="w-full lg:w-4/12" :mask="['(##) ####-####', '(##) #####-####']" :masked="true"
          name="telefoneFinanceiro" v-model="dadosEmpresa.telefoneFinanceiro" :error="errors.first('telefoneFinanceiro')"
          label="Telefone Responsável Financeiro*" v-validate="'required|min:14|max:15'"></v-input>
      </div>
      <p class="form_title">REFERÊNCIA COMERCIAL</p>
      <div class="row">
        <v-input class="w-full lg:w-6/12 lg:pr-6" name="referenciaComercial1_Nome"
          v-model="dadosEmpresa.referenciaComercial1_Nome" :error="errors.first('referenciaComercial1_Nome')"
          label="Nome*" v-validate="'required'"></v-input>
        <v-input class="w-full lg:w-6/12" :mask="['(##) ####-####', '(##) #####-####']" :masked="true"
          name="referenciaComercial1_Telefone" v-model="dadosEmpresa.referenciaComercial1_Telefone"
          :error="errors.first('referenciaComercial1_Telefone')" label="Telefone*"
          v-validate="'required|min:14|max:15'"></v-input>
        <v-input class="w-full lg:w-6/12 lg:pr-6" name="referenciaComercial2_Nome"
          v-model="dadosEmpresa.referenciaComercial2_Nome" :error="errors.first('referenciaComercial2_Nome')"
          label="Nome*" v-validate="'required'"></v-input>
        <v-input class="w-full lg:w-6/12" :mask="['(##) ####-####', '(##) #####-####']" :masked="true"
          name="referenciaComercial2_Telefone" v-model="dadosEmpresa.referenciaComercial2_Telefone"
          :error="errors.first('referenciaComercial2_Telefone')" label="Telefone*"
          v-validate="'required|min:14|max:15'"></v-input>
      </div>
      <p class="form_title">CONTA BANCÁRIA</p>
      <p class="warning"><b>Dica:</b> informe uma conta bancária devidamente ativa e registrada no CNPJ da sua empresa,
        incluindo os digitos. Caso seu empréstimo seja aprovado, esta será a conta
        utilizada para crédito.
        *Não aceitamos conta de pessoa física, apenas jurídica.</p>
      <div class="row">
        <v-input :options="banks" itemValue='instituicaoFerbranId' :itemText="['codigo', 'nome']"
          class="w-full lg:w-6/12 lg:pr-6" name="contaBancaria1_InstituicaoFerbranId"
          v-model="dadosEmpresa.contaBancaria1_InstituicaoFerbranId"
          :error="errors.first('contaBancaria1_InstituicaoFerbranId')" label="Conta Bancária*"
          v-validate="{ required: true, is_not: -1 }"></v-input>
        <v-input class="w-full lg:w-2/12 lg:pr-6" name="contaBancaria1_Agencia"
          v-model="dadosEmpresa.contaBancaria1_Agencia" :error="errors.first('contaBancaria1_Agencia')" label="Agência*"
          v-validate="'required'"></v-input>
        <v-input class="w-full lg:w-2/12 lg:pr-6" name="contaBancaria1_Conta" v-model="dadosEmpresa.contaBancaria1_Conta"
          :error="errors.first('contaBancaria1_Conta')" label="Conta*" v-validate="'required'"></v-input>
        <v-input :options="accounts" itemValue='id' :itemText="'descricao'" class="w-full lg:w-2/12"
          name="contaBancaria1_TipoConta" v-model="dadosEmpresa.contaBancaria1_TipoConta"
          :error="errors.first('contaBancaria1_TipoConta')" label="Tipo Conta*"
          v-validate="{ required: true, is_not: -1 }"></v-input>

      </div>
      <hr class="h-px bg-gray-600 opacity-50 my-6">
      <div class="row">
        <p class="text-sm font-semibold opacity-75 mb-1">Caso tenha outra conta bancária em nome da empresa, informe os
          dados.</p>
        <v-input :options="banks" itemValue='instituicaoFerbranId' :itemText="['codigo', 'nome']" itemSeparator="-"
          class="w-full lg:w-6/12 lg:pr-6" name="contaBancaria2_InstituicaoFerbranId"
          v-model="dadosEmpresa.contaBancaria2_InstituicaoFerbranId"
          :error="errors.first('contaBancaria2_InstituicaoFerbranId')" label="Conta Bancária"></v-input>
        <v-input class="w-full lg:w-2/12 lg:pr-6" name="contaBancaria2_Agencia"
          v-model="dadosEmpresa.contaBancaria2_Agencia" :error="errors.first('contaBancaria2_Agencia')" label="Agência">
        </v-input>
        <v-input class="w-full lg:w-2/12 lg:pr-6" name="contaBancaria2_Conta" v-model="dadosEmpresa.contaBancaria2_Conta"
          :error="errors.first('contaBancaria2_Conta')" label="Conta">
        </v-input>
        <v-input :options="accounts" itemValue='id' :itemText="'descricao'" class="w-full lg:w-2/12"
          name="contaBancaria2_TipoConta" v-model="dadosEmpresa.contaBancaria2_TipoConta"
          :error="errors.first('contaBancaria2_TipoConta')" label="Tipo Conta"></v-input>
      </div>
      <div class="boxes_group">
        <p class="radio_label mb-2">Declaro que as informações acima são a expressão da verdade e autorizo a Instituição
          a consultar órgãos especificos de proteção ao crédito e central de risco de crédito do Banco Central do
          Brasil, permitindo a análise dos créditos a conceder, o arquivamento de meus dados cadastrais, idoneidade e
          histórico, assim como o fornecimento de informações e de performance de pagamento de minhas operações para a
          Central de Risco de Crédito, administrada pelo Banco Central do Brasil, Associações Comerciais, SERASA e
          demais órgãos similares, públicos ou privados , os quais poderão deles se utilizar, respeitadas as disposições
          legais vigentes. Declaro ainda estar ciente de que deverei informar qualquer alteração à instituição nas
          informações acima prestadas.</p>
        <v-radio class="inline" name="termos" v-model="termos" label="Sim" :value="true" v-validate="'required:true'">
        </v-radio>
        <v-radio class="inline" name="termos" v-model="termos" label="Não" :value="false"></v-radio>
        <p class="text-xs text-red-600">{{ errors.first("termos") }}</p>
      </div>
      <div class="row items-center lg:items-start justify-center">
        <v-button color="primary" class="my-4" size="lg" :loading="loading">Salvar e Avançar</v-button>
      </div>
    </form>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VRadio from '@/components/Inputs/Radio'
import VButton from '@/components/Buttons/Button'

import Cep from '@/services/Commons/Cep'
import Forms from '@/services/Business/Forms'
import StatusManager from '@/services/Business/StatusManager'

export default {
  name: 'company-data',
  components: { VInput, VButton, VRadio },
  data() {
    return {
      termos: false,
      dadosEmpresa: {
        razaoSocial: '',
        nomeComercialFantasia: '',
        inscricaoEstadual: null,
        telefone: '',
        email: '',
        enderecoSedeSocial_Logradouro: '',
        enderecoSedeSocial_Numero: '',
        enderecoSedeSocial_Bairro: '',
        enderecoSedeSocial_Complemento: '',
        enderecoSedeSocial_Cidade: -1,
        enderecoSedeSocial_UF: -1,
        enderecoSedeSocial_CEP: '',

        dataConstituicao: '',
        capitalSocial: '',
        website: '',
        instagram: '',
        ramoAtividade: '',
        principaisProdutosVendidos: '',
        faturamentoUltimos90Dias: '',

        quantidadeEmpregados: '',
        quantidadeSocios: '',

        /*vendaDoUltimoExercicio: '',
        comprasUltimoExercicio: '',*/
        patrimonioAproximado: '',

        nomeFinanceiro: '',
        emailFinanceiro: '',
        telefoneFinanceiro: '',

        referenciaComercial1_Nome: '',
        referenciaComercial1_Telefone: '',
        referenciaComercial2_Nome: '',
        referenciaComercial2_Telefone: '',

        contaBancaria1_InstituicaoFerbranId: -1,
        contaBancaria1_Agencia: '',
        contaBancaria1_Conta: '',
        contaBancaria1_TipoConta: -1,
        contaBancaria2_InstituicaoFerbranId: -1,
        contaBancaria2_Agencia: '',
        contaBancaria2_Conta: '',
        contaBancaria2_TipoConta: -1
      },

      loading: false,
      loadingPage: true,
      banks: [],
      accounts: [],
      cities: [],
      ufs: []
    }
  },
  methods: {
    async getCep() {
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.dadosEmpresa.enderecoSedeSocial_CEP)) {
        try {
          const { cidade, uf, complemento, logradouro, bairro } = (await Cep.getCep(this.dadosEmpresa.enderecoSedeSocial_CEP)).data.data
          await this.getCities(uf)
          this.dadosEmpresa = { ...this.dadosEmpresa, enderecoSedeSocial_Cidade: cidade, enderecoSedeSocial_UF: uf, enderecoSedeSocial_Complemento: complemento, enderecoSedeSocial_Logradouro: logradouro, enderecoSedeSocial_Bairro: bairro }
        } catch (error) {
          console.error(error)
        }
      }
    },
    async getCities(uf) {
      try {
        const cities = (await Cep.getCities(uf || this.dadosEmpresa.enderecoSedeSocial_UF)).data.data
        this.cities = cities.map(city => ({ name: city }))
      } catch (err) {
        console.error(err)
      }
    },
    async submit() {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            if (this.dadosEmpresa.contaBancaria2_InstituicaoFerbranId === -1 &&
              this.dadosEmpresa.contaBancaria2_Agencia === '' &&
              this.dadosEmpresa.contaBancaria2_Conta === '') {
              delete this.dadosEmpresa.contaBancaria2_InstituicaoFerbranId
              delete this.dadosEmpresa.contaBancaria2_Agencia
              delete this.dadosEmpresa.contaBancaria2_Conta
              delete this.dadosEmpresa.contaBancaria2_TipoConta
            }
            await Forms.putBusinessData({
              ...this.dadosEmpresa,
              inscricaoEstadual: this.dadosEmpresa.inscricaoEstadual && this.dadosEmpresa.inscricaoEstadual.trim().length ? this.dadosEmpresa.inscricaoEstadual : null
            })
            this.$store.dispatch('notification', { type: 'success', message: 'Dados salvos com sucesso!' })
            this.$emit('updateBusiness')
            this.$router.push('/tomador/perfil/dados-pessoais')
          } catch (error) {
            this.loading = false
            //this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro. Revise o formulário.' })
            if (error.response.data.erros) {
              Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo, msg: erro }))

              let erros = "";
              for (var e in error.response.data.erros) {
                erros += error.response.data.erros[e] + ' ';
              }

              this.$store.dispatch('notification', { type: 'error', message: erros })
            }
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  },
  async mounted() {
    try {
      this.banks = (await Forms.getBanks()).data.data
    } catch (error) {
      console.error(error)
    }
    try {
      this.accounts = (await Forms.getEnums()).data.data.tipoConta
    } catch (error) {
      console.error(error)
    }
    try {
      const ufs = (await Cep.getUfs()).data.data
      this.ufs = ufs.map(uf => ({ name: uf }))
    } catch (err) {
      console.error(err)
    }
    try {
      const response = (await Forms.getBusinessData()).data.data
      if (response.enderecoSedeSocial_UF) {
        await this.getCities(response.enderecoSedeSocial_UF)
      }
      Object.keys(response).forEach(key => {
        if (response[key] !== null && response[key] !== '') this.dadosEmpresa[key] = response[key]
      })
    } catch (err) {
      console.error(err)
    }
    this.loadingPage = false
  }
}
</script>

<style lang="sass" scoped>

.main
  @apply w-full
  background: #F5F5F5

.content
  @apply w-full flex flex-col items-center bg-white px-6
  min-height: 150px

.title
  @apply font-bold text-xl text-primary text-center mt-4 mb-8
  @screen lg
    @apply text-2xl

.form_title
  @apply font-bold text-lg text-black mt-6 mb-1
  @screen lg
    @apply text-xl

.row
  @apply block flex flex-col
  @screen lg
    @apply flex flex-row flex-wrap

.warning
  @apply text-orange-600 text-sm border border-red-600 shadow-md rounded-lg p-2 mt-1 mb-4
  @screen lg 
    @apply mt-1 mb-4

.backdrop
  @apply absolute w-full h-full bg-white text-center text-4xl text-primary
  z-index: 60
  opacity: 0.9

.spin
  animation: rotatey 2s linear infinite

@keyframes rotatey
  from
    transform: rotateY(0deg)
  to
    transform: rotateY(360deg)

</style>
