import api from '@/plugins/axios'

const base = 'v1/tomador/'

export default {
  getEnums () {
    return api.get(`${base}enums`)
  },
  getBanks () {
    return api.get(`v1/bancos`)
  },

  getRequesterData () {
    return api.get(`${base}dados-solicitante`)
  },
  putRequesterData (data) {
    return api.put(`${base}dados-solicitante`, data)
  },

  getBusinessData () {
    return api.get(`${base}dados-empresa`)
  },
  putBusinessData (data) {
    return api.put(`${base}dados-empresa`, data)
  },

  getContactAuthorization () {
    return api.get(`${base}autorizacoes-contato`)
  },
  putContactAuthorization (data) {
    return api.put(`${base}autorizacoes-contato`, data)
  }
}
