import api from '@/plugins/axios'

const base = 'v1/tomador/'

export default {
    getStatus() {
        return api.get(`${base}status-cadastro`)
    },
    getInfoInstallments(id) {
        return api.get(`${base}emprestimos/${id}`)
    },
    getAcordos(id) {
        return api.get(`${base}emprestimos/${id}/acordos`)
    },
    datasPermitidas() {
        return api.get(`${base}emprestimos/datas-permitidas-atualizar-boleto`)
    },
    simularAtualizacaoBoleto(data) {
        return api.post(`${base}emprestimos/simular-atualizacao-boleto`, data)
    },
    simularAtualizacaoBoletoAcordo(data) {
        return api.post(`${base}emprestimos/simular-atualizacao-boleto-acordo`, data)
    },
    gerarBoletoAtualizado(data) {
        return api.put(`${base}emprestimos/gerar-boleto-atualizado`, data)
    },
    gerarBoletoAtualizadoAcordo(data) {
        return api.put(`${base}emprestimos/gerar-boleto-atualizado-acordo`, data)
    },
    linkWhereBy(guid) {
        return api.get(`${base}link-whereby/${guid}`)
    }
}