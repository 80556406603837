import Dashboard from '@/services/Business/Dashboard'
import Store from '@/store'


export default {
    async refresh() {
        try {
            const response = (await Dashboard.getStatus()).data.data
            const {
                faltamDocs: incompleteProfile,
                nomeFantasia: fantasyName,
                emailConfirmado: emailConfirmed,
                docsEmAnalise: docsEmAnalise,
                preencheuDadosEmpresa: preencheuDadosEmpresa,
                preencheuDadosSolicitante: preencheuDadosSolicitante,
                status: status
            } = response


            const business = {
                incompleteProfile,
                fantasyName,
                emailConfirmed,
                step: 0,
                docsEmAnalise,
                preencheuDadosEmpresa,
                preencheuDadosSolicitante,
                status
            }

            if (!response.preencheuDadosEmpresa) business.step = 1
            else if (!response.preencheuDadosSolicitante) business.step = 2
            else if (
                response.preencheuInformacoesTodosSocios &&
                response.finalizouPreenchimento && !response.enviouTodosDocs
            ) { business.step = 4 }
            else if (response.enviouTodosDocs && !response.preencheuInformacoesTodosSocios && response.preencheuDadosEmpresa && response.preencheuDadosSolicitante) business.step = 3
            else if (response.enviouTodosDocs && response.preencheuInformacoesTodosSocios && response.preencheuDadosEmpresa && response.preencheuDadosSolicitante) business.step = 5
            else if (!response.enviouTodosDocs) business.step = 4
            business.sentAnyDoc = response.enviouAlgumDoc
            business.docsInReview = response.docsEmAnalise
            business.incompleteProfile = response.faltamDocs || !response.preencheuDadosEmpresa || !response.preencheuDadosSolicitante;
            business.docsEmAnalise = response.docsEmAnalise

            Store.dispatch('setBusiness', business)

        } catch { }
    }
}