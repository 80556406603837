import api from '@/plugins/axios'

const base = 'v1/cep'

export default {
  getCep (cep) {
    return api.get(`${base}?cep=${cep}`)
  },
  getUfs () {
    return api.get(`${base}/uf`)
  },
  getCities (uf) {
    return api.get(`${base}/${uf}/cidades`)
  }
}
